<div class="container">
  <div class="row">
    <div>
      <img src="/assets/img/Archimdelife-Logo-CMYK.svg" width="200px" />
    </div>
    <div style="margin-top: 3em">
      <img src="../../assets/img/worldmap_dots_archimed.jpg" width="500px" />
    </div>
    <div style="margin-top: 3em">
      <div [innerHtml]="'noservice.message' | translate"></div>
      <!-- <h5>Thank you for visiting the ARCHIMEDlife WEBPORTAL!</h5>
      <p>
        Our laboratory services are available to physicians and health care
        professionals worldwide. Currently, access to our services and WEBPORTAL
        is limited in the United States and Canada.
      </p>
      <p>
        For testing and service options within the US and Canada, please contact
        us at <a href="mailto:info@archimedlife.com">info@archimedlife.com</a>
      </p> -->
    </div>
  </div>
</div>
