import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthState } from './auth.state';
import { AuthenticationService } from './authentication.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../service/rest/user.service';
import { UnloadService } from './unload.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  returnUrl = '/';

  constructor(
    private router: Router,
    private authState: AuthState,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      // We trigger loading currentUser into the AppState here, so in can ActivateChild we can be sure the
      // there is a user in the appState and we can do additional checks (e.g. roles, permissions) there
      this.authState.fetchCurrentUser();
      this.authService.initAutoLogout();
      UnloadService.addActiveTab();
      return true;
    }

    if (state.url !== '/login' && state.url !== '/logout') {
      this.returnUrl = state.url;
    }
    // not logged in so redirect to login page with the return url
    const redirect: UrlTree = this.router.parseUrl('/login');
    redirect.queryParams = { returnUrl: this.returnUrl };
    return redirect;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (this.authService.isLoggedIn()) {
      return this.authState.selectCurrentUser().pipe(
        map((user) => {
          if (user?.id == null) {
            return false;
          } else {
            if (childRoute.data['roles']) {
              return UserService.hasRoles(user, childRoute.data['roles'])
                ? true
                : this.router.parseUrl('/');
            } else {
              return true;
            }
          }
        })
      );
    }
    // not logged in so redirect to login page with the return url
    const redirect: UrlTree = this.router.parseUrl('/login');
    redirect.queryParams = { returnUrl: this.returnUrl };
    return of(redirect);
  }
}
