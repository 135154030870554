import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MissingTranslationHandler,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthenticationService } from './auth/authentication.service';
import { RoleGuard } from './auth/role.guard';
import { AnalysisTemplatesComponent } from './components/analysis-templates.component';
import { AnalysistemplatesComponent } from './components/analysis-templates-bo.component';
import { ExternalPoolComponent } from './components/cms/external-pool.component';
import { PoolDetailComponent } from './components/cms/pool-detail.component';
import { ResourcesPageComponent } from './components/cms/resources-page.component';
import { TemplateInfoComponent } from './components/cms/template-info.component';
import { APP_CONFIG, AppSettings } from './model/appsettings';
import { KeepHtmlPipe } from './pipe/keepHtml.pipe';
import { PrepareDateForServicePipe } from './pipe/prepare-date-for-service.pipe';
import { RemoveHTTPPipe } from './pipe/remove-http.pipe';
import { RemovePDFExtensionPipe } from './pipe/remove-pdf-extension.pipe';
import { ShortenHtmledTextPipe } from './pipe/shorten-htmled-text.pipe';
import { StripHtmlPipe } from './pipe/stripHtml.pipe';
import { UnslugPipe } from './pipe/unslug.pipe';
import { LoadingService } from './service/loading.service';
import { AnalysisService } from './service/rest/analysis.service';
import { AnalysisTemplateService } from './service/rest/analysistemplate.service';
import { BulkOrderRestService } from './service/rest/bulk-order.rest-service';
import { CMSService } from './service/rest/cms.service';
import { RegionRestService } from './service/rest/region.rest-service';
import { CroService } from './service/rest/cro.service';
import { DlxService } from './service/rest/dlx.service';
import { InvoiceService } from './service/rest/invoice.service';
import { KitOrderService } from './service/rest/kit-order.service';
import { LabWorkerService } from './service/rest/labWorker.service';
import { NewsService } from './service/rest/news.service';
import { NewsletterService } from './service/rest/newsletter.service';
import { PatientService } from './service/rest/patient.service';
import { PhysicianService } from './service/rest/physician.service';
import { PortalService } from './service/rest/portal.service';
import { ReviewService } from './service/rest/review.service';
import { TranslationLoadService } from './service/rest/translation-load.service';
import { TranslationService } from './service/rest/translation.service';
import { UserService } from './service/rest/user.service';
import { VipService } from './service/rest/vip.service';
import { WhatsappService } from './service/rest/whatsapp.service';
import { DefaultMissingTranslationHandler } from './translation/default-missing-translation-handler';
import { BackGuard } from './util/back.guard';
// import { CountryUtils } from './util/country-utils';
import { AllowOnlyNumbersReactiveDirective } from './util/directives/allow-only-numbers-reactive.directive';
import { DisableControlDirective } from './util/directives/disable-formcontrol.directive';
import { DisableGroupDirective } from './util/directives/disable-formgroup.directive';
import { InfiniteScrollComponent } from './util/infinite-scroll/infinite-scroll.component';
import { UniqueUsernameValidatorDirective } from './util/validators/unique-username.validator';
import { PoolService } from './service/rest/pool.service';
import { FormatPhonePipe } from './pipe/format-phone.pipe';
import { KitTypeService } from './service/rest/kit-type.service';
import { PublicRestService } from './service/rest/public.rest-service';
import { CountryRestService } from './service/rest/country.rest-service';
import { DelegateRestService } from './service/rest/delegate.rest-service';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ArchimedStepperComponent } from './components/stepper/archimed-stepper.component';
import { ImmunologyTemplatesComponent } from './components/immunology-templates.component';
import { CustomerInfoRestService } from './service/rest/customer-info.rest-service';
import { PriceRestService } from './service/rest/price.rest-service';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireResultsComponent } from './questionnaire/questionnaire-results.component';
import { PickupRequestRestService } from './service/rest/pickup-request.rest-service';

// export function createTranslateLoader(http: HttpClient) {
//   return new TranslationLoader(http);
// }

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkStepperModule,
    TranslateModule.forChild(
      // {
      // loader: {
      //   provide: TranslateLoader,
      //   useFactory: createTranslateLoader, // Note: use HttpLoader provided with ngx-translate?
      //   deps: [HttpClient],
      // },
      // }
      {
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: DefaultMissingTranslationHandler,
        },
      }
    ),
  ],
  declarations: [
    UnslugPipe,
    KeepHtmlPipe,
    RemoveHTTPPipe,
    ShortenHtmledTextPipe,
    StripHtmlPipe,
    RemovePDFExtensionPipe,
    PrepareDateForServicePipe,
    FormatPhonePipe,
    InfiniteScrollComponent,
    DisableControlDirective,
    DisableGroupDirective,
    AllowOnlyNumbersReactiveDirective,
    AnalysistemplatesComponent,
    AnalysisTemplatesComponent,
    UniqueUsernameValidatorDirective,
    ExternalPoolComponent,
    PoolDetailComponent,
    ResourcesPageComponent,
    TemplateInfoComponent,
    ArchimedStepperComponent,
    ImmunologyTemplatesComponent,
    QuestionnaireComponent,
    QuestionnaireResultsComponent,
  ],
  exports: [
    UnslugPipe,
    KeepHtmlPipe,
    RemoveHTTPPipe,
    ShortenHtmledTextPipe,
    StripHtmlPipe,
    RemovePDFExtensionPipe,
    PrepareDateForServicePipe,
    InfiniteScrollComponent,
    TranslateModule,
    DisableControlDirective,
    DisableGroupDirective,
    AllowOnlyNumbersReactiveDirective,
    AnalysistemplatesComponent,
    AnalysisTemplatesComponent,
    UniqueUsernameValidatorDirective,
    ExternalPoolComponent,
    PoolDetailComponent,
    ResourcesPageComponent,
    TemplateInfoComponent,
    ArchimedStepperComponent,
    CdkStepperModule,
    ImmunologyTemplatesComponent,
    QuestionnaireComponent,
    QuestionnaireResultsComponent,
  ],
})
export class ArchimedCommonModule {
  public static forRoot(
    config: AppSettings
  ): ModuleWithProviders<ArchimedCommonModule> {
    return {
      ngModule: ArchimedCommonModule,
      providers: [
        AnalysisService,
        AnalysisTemplateService,
        AuthenticationService,
        AuthGuard,
        RoleGuard,
        BackGuard,
        AuthInterceptor,
        RegionRestService,
        LoadingService,
        PatientService,
        PhysicianService,
        PortalService,
        ReviewService,
        TranslationLoadService,
        TranslateService,
        UserService,
        InvoiceService,
        KitOrderService,
        BulkOrderRestService,
        DlxService,
        TranslationService,
        LabWorkerService,
        CMSService,
        NewsletterService,
        WhatsappService,
        CroService,
        VipService,
        NewsService,
        PoolService,
        KitTypeService,
        PublicRestService,
        CountryRestService,
        DelegateRestService,
        CustomerInfoRestService,
        PriceRestService,
        PickupRequestRestService,
        {
          provide: APP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
