import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnalysisPool } from '../../model/analysispool';
import { AnalysisTemplate } from '../../model/analysisTemplate';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PagedResult } from '../../util/pagedresult';
import { BaseService } from '../base.service';
import { RestUtils } from '../../util/restUtils';
import { PagedRequest } from '../../util/pagedRequest';
import { JsonFormData, Questionnaire } from '../../questionnaire/models';

@Injectable()
export class AnalysisTemplateService extends BaseService {
  private url = this.endpointUrl + '/v1/analysis-template';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  loadSubAnalysisTemplates(
    analysisTemplate: AnalysisTemplate,
    countryId?: number,
    userId?: number
  ): Observable<AnalysisTemplate[]> {
    let params: HttpParams = new HttpParams();
    if (countryId != null) params = params.set('country', countryId);
    if (userId != null) params = params.set('user', userId);
    return this.http
      .get<AnalysisTemplate[]>(
        `${this.url}/${analysisTemplate.id}/sub-templates`,
        { params }
      )
      .pipe(
        catchError(
          this.handleError<AnalysisTemplate[]>('loadAnalysisSubTemplates', [])
        )
      );
  }

  loadAnalysisPools(parentId?: number): Observable<AnalysisPool[]> {
    let params: HttpParams = new HttpParams();
    params = parentId ? params.set('parent-id', parentId) : params;
    return this.http.get<AnalysisPool[]>(`${this.url}/pools`, { params });
  }

  loadAnalysisTemplatesFromPool(
    analysisPoolId: number
  ): Observable<AnalysisTemplate[]> {
    return this.http.get<AnalysisTemplate[]>(
      `${this.url}/pool/${analysisPoolId}`
    );
  }

  loadAnalysisTemplatesFromPoolAdmin(
    analysisPoolId: number,
    countryId?: number,
    userId?: number
  ): Observable<AnalysisTemplate[]> {
    let params: HttpParams = new HttpParams();
    if (countryId != null) {
      params = params.set('country', countryId);
    } else if (userId != null) {
      params = params.set('user', userId);
    }
    return this.http.get<AnalysisTemplate[]>(
      `${this.url}/analysis-template/${analysisPoolId}`,
      { params }
    );
  }

  updateAnalysisTemplatesForPoolAdmin(
    analysisPoolId: number,
    newTemplates: AnalysisTemplate[],
    countryId?: number,
    userId?: number
  ): Observable<void> {
    let params: HttpParams = new HttpParams();
    if (countryId != null) {
      params = params.set('country', countryId);
    } else if (userId != null) {
      params = params.set('user', userId);
    }
    return this.http.put<void>(
      `${this.url}/analysis-template/${analysisPoolId}`,
      newTemplates,
      { params }
    );
  }

  updateAnalysisTemplatesForParentAdmin(
    parentId: number,
    newTemplates: AnalysisTemplate[],
    countryId?: number,
    userId?: number
  ): Observable<void> {
    let params: HttpParams = new HttpParams();
    if (countryId != null) {
      params = params.set('country', countryId);
    } else if (userId != null) {
      params = params.set('user', userId);
    }
    return this.http.put<void>(
      `${this.url}/analysis-template/${parentId}/subtemplates`,
      newTemplates,
      { params }
    );
  }

  loadAnalysisTemplateTreeForPool(
    analysisPoolId: number
  ): Observable<AnalysisTemplate[]> {
    return this.http.get<AnalysisTemplate[]>(
      `${this.url}/pool/${analysisPoolId}/tree`
    );
  }

  getFeaturedTemplatesForLoggedInUser(): Observable<AnalysisTemplate[]> {
    return this.http
      .get<AnalysisTemplate[]>(`${this.url}/featured`)
      .pipe(
        catchError(
          this.handleError<AnalysisTemplate[]>(
            'getFeaturedTemplatesForLoggedInUser',
            []
          )
        )
      );
  }

  // admin calls
  loadAllAnalysisPools(portalId?: number): Observable<AnalysisPool[]> {
    let params = new HttpParams();
    params = portalId != null ? params.set('portal-id', `${portalId}`) : params;
    return this.http
      .get<AnalysisPool[]>(this.url + `/pools/all`, { params })
      .pipe(
        catchError(this.handleError<AnalysisPool[]>('loadAllAnalysisPools', []))
      );
  }

  loadAnalysisPoolsForUserAndPortal(
    physicianId: number,
    portalId?: number,
    effective?: boolean
  ): Observable<AnalysisPool[]> {
    let params: HttpParams = new HttpParams();

    if (portalId != null) {
      params = params.set('portalId', `${portalId}`);
    }
    if (effective != null) {
      params = params.set('effective', `${effective}`);
    }

    return this.http
      .get<AnalysisPool[]>(
        this.url + `/pools/user/${physicianId}?${params.toString()}`
      )
      .pipe(
        catchError(
          this.handleError<AnalysisPool[]>('AnalysisPoolsForUserAndPortal', [])
        )
      );
  }

  loadFiltersForAnalysisPool(poolId: number): Observable<any> {
    return this.http
      .get<any>(`${this.url}/analysis-pool/${poolId}/filters`)
      .pipe(catchError(this.handleError<any>('loadFiltersForAnalysisPool')));
  }

  loadFiltersForAnalysisTemplateParent(parentId: number): Observable<any> {
    return this.http
      .get<any>(`${this.url}/analysis-template/${parentId}/filters`)
      .pipe(
        catchError(
          this.handleError<any>('loadFiltersForAnalysisTemplateParent')
        )
      );
  }

  loadAnalysisPool(id: number): Observable<AnalysisPool> {
    return this.http
      .get<AnalysisPool>(`${this.url}/analysis-pool/${id}`)
      .pipe(catchError(this.handleError<any>('loadAnalysisPool')));
  }

  loadParentTemplatesForPortal(
    portalId: number
  ): Observable<AnalysisTemplate[]> {
    return this.http
      .get<AnalysisTemplate[]>(`${this.url}/portal/${portalId}`)
      .pipe(
        catchError(
          this.handleError<AnalysisTemplate[]>('loadParentTemplateForPortal')
        )
      );
  }

  getLeafsFiltered(
    physicianId: number,
    bulkOrderEnabled: boolean,
    pagedRequest: PagedRequest,
    portalId?: number
  ): Observable<PagedResult<AnalysisTemplate>> {
    const params = RestUtils.pagedRequestToHttpParams(pagedRequest);
    const headers =
      portalId != null
        ? this.getHeadersWithPortalId(portalId)
        : new HttpHeaders();
    pagedRequest.filters = {
      ...pagedRequest.filters,
      physicianId: { value: physicianId },
      bulkOrderEnabled: { value: bulkOrderEnabled },
    };
    return this.http.post<PagedResult<AnalysisTemplate>>(
      `${this.url}/analysis-template`,
      pagedRequest.filters,
      { params, headers }
    );
  }

  getFiltered(
    templateId: number,
    title: string,
    pagedRequest: PagedRequest
  ): Observable<PagedResult<AnalysisTemplate>> {
    const params = RestUtils.pagedRequestToHttpParams(pagedRequest);
    pagedRequest.filters = {
      ...pagedRequest.filters,
    };
    return this.http.post<PagedResult<AnalysisTemplate>>(
      `${this.url}/analysis-templates`,
      pagedRequest.filters,
      { params }
    );
  }

  updateTemplate(template: AnalysisTemplate): Observable<AnalysisTemplate> {
    return this.http.put<AnalysisTemplate>(
      `${this.url}/analysis-template/update`,
      template
    );
  }

  createTemplate(template: AnalysisTemplate): Observable<AnalysisTemplate> {
    return this.http.post<AnalysisTemplate>(
      `${this.url}/analysis-template/create`,
      template
    );
  }

  cloneTemplate(templateId: number): Observable<AnalysisTemplate> {
    return this.http.post<AnalysisTemplate>(
      `${this.url}/analysis-template/${templateId}/clone`,
      templateId
    );
  }

  // solr search calls

  rebuildSolrIndex(): Observable<any> {
    return this.http
      .get(`${this.url}/solr/index`)
      .pipe(catchError(this.handleError<any>('rebuildSolrIndex')));
  }

  findTemplates(searchTerm: string): Observable<PagedResult<AnalysisTemplate>> {
    let params: HttpParams = new HttpParams();

    if (searchTerm != null) {
      params = params.set('searchTerm', `${searchTerm}`);
    }
    params = params.set('size', 50);
    return this.http
      .get<PagedResult<AnalysisTemplate>>(`${this.url}/solr/search`, {
        params: params,
      })
      .pipe(
        catchError(
          this.handleError<PagedResult<AnalysisTemplate>>('findTemplates')
        )
      );
  }

  findSuggestions(searchTerm: string): Observable<any[]> {
    let params: HttpParams = new HttpParams();

    if (searchTerm != null) {
      params = params.set('searchTerm', `${searchTerm}`);
    }
    return this.http
      .get<any[]>(`${this.url}/solr/suggest`, { params: params })
      .pipe(catchError(this.handleError<any[]>('findSuggestions')));
  }

  rebuildParameterCatalog(): Observable<any> {
    return this.http
      .get(`${this.url}/solr/parameter`)
      .pipe(catchError(this.handleError<any>('rebuildParameterCatalog')));
  }

  getLeafTemplatesForPool(poolId: number) {
    return this.http.get<AnalysisTemplate[]>(
      `${this.url}/pool/${poolId}/leafs`
    );
  }

  getQuestionnaireForm(questionnaireId: number): Observable<JsonFormData> {
    return this.http
      .get<JsonFormData>(`${this.url}/questionnaire/${questionnaireId}`)
      .pipe(catchError(this.handleError<JsonFormData>('getQuestionnaire')));
  }

  getQuestionnaireOptions(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.url}/questionnaire-options`)
      .pipe(catchError(this.handleError<any[]>('getQuestionnaireOptions')));
  }

  getQuestionnaires(): Observable<Questionnaire[]> {
    return this.http
      .get<Questionnaire[]>(`${this.url}/questionnaires`)
      .pipe(catchError(this.handleError<Questionnaire[]>('getQuestionnaires')));
  }

  updateQuestionnaire(questionnaire: Questionnaire): Observable<Questionnaire> {
    return this.http.put<Questionnaire>(
      `${this.url}/questionnaire/update`,
      questionnaire
    );
  }

  createQuestionnaire(questionnaire: Questionnaire): Observable<Questionnaire> {
    return this.http.post<Questionnaire>(
      `${this.url}/questionnaire/create`,
      questionnaire
    );
  }
}
