import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PickupRequest } from '../../model/pickupRequest';
import { PagedResult } from '../../util/pagedresult';
import { RestUtils } from '../../util/restUtils';
import { TableLazyLoadEvent } from 'primeng/table';
import { AnalysisCompact } from '../../model/analysisCompact';

@Injectable({ providedIn: 'root' })
export class PickupRequestRestService extends BaseService {
  private url = `${this.endpointUrl}/v1/pickup-request`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  createPickupRequest(pickupRequest: PickupRequest): Observable<void> {
    return this.http.post<void>(`${this.url}`, pickupRequest);
  }

  loadPickupRequests(
    lazyLoadEvent: TableLazyLoadEvent,
    portalId?: number
  ): Observable<PagedResult<PickupRequest>> {
    const params = RestUtils.getPaginationHttpParams(lazyLoadEvent);
    const headers =
      portalId != null
        ? this.getHeadersWithPortalId(portalId)
        : new HttpHeaders();
    return this.http.post<PagedResult<PickupRequest>>(
      `${this.url}/list`,
      lazyLoadEvent.filters,
      { params, headers }
    );
  }

  getLatestPickupRequest(): Observable<PickupRequest> {
    return this.http.get<PickupRequest>(`${this.url}/latest`);
  }

  getNotArrivedAnalyses() {
    return this.http.get<AnalysisCompact[]>(`${this.url}/not-arrived-analyses`);
  }
}
