import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@archimed-frontend/archimed-common';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppStateService } from '../../shared/app-state.service';

@Component({
  templateUrl: 'logout.component.html',
})
export class LogoutComponent implements OnInit {
  state$ = this.appState.select();

  constructor(
    private appState: AppStateService,
    private authenticationService: AuthenticationService,
    private matomoTracker: MatomoTracker,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.appState.set({ currentUser: undefined });
    this.authenticationService.logout(false);
    this.authenticationService.navigateToLogin();
    this.matomoTracker.setDocumentTitle(this.titleService.getTitle());
    this.matomoTracker.setCustomUrl('/logout');
    this.matomoTracker.trackPageView();
  }
}
