export const ANALYSIS_STATUS = {
  NEW: { label: 'New', value: 'NEW' },
  SAMPLE_ARRIVED: { label: 'Sample arrived', value: 'SAMPLE_ARRIVED' },
  SAMPLE_ON_HOLD_ICF_MISSING: {
    label: 'Sample on hold ICF missing',
    value: 'SAMPLE_ON_HOLD_ICF_MISSING',
  },
  SAMPLE_ON_HOLD: {
    label: 'Sample on hold information missing',
    value: 'SAMPLE_ON_HOLD',
  },
  SAMPLE_REJECTED: { label: 'Sample rejected', value: 'SAMPLE_REJECTED' },
  SAMPLE_LOW_QUALITY: {
    label: 'Sample low quality',
    value: 'SAMPLE_LOW_QUALITY',
  },
  REPORT_AVAILABLE: { label: 'Report available', value: 'REPORT_AVAILABLE' },
  REPORT_CANCELED: { label: 'Report cancelled', value: 'REPORT_CANCELED' },
  KIT_SHIPPED: { label: 'Kit shipped', value: 'KIT_SHIPPED' },
};

export const ANALYSIS_STATUS_LIST = [
  ANALYSIS_STATUS.NEW,
  ANALYSIS_STATUS.SAMPLE_ARRIVED,
  ANALYSIS_STATUS.SAMPLE_ON_HOLD_ICF_MISSING,
  ANALYSIS_STATUS.SAMPLE_ON_HOLD,
  ANALYSIS_STATUS.SAMPLE_REJECTED,
  ANALYSIS_STATUS.SAMPLE_LOW_QUALITY,
  ANALYSIS_STATUS.REPORT_AVAILABLE,
  ANALYSIS_STATUS.REPORT_CANCELED,
  ANALYSIS_STATUS.KIT_SHIPPED,
];
