import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { catchError, EMPTY, filter, switchMap, tap } from 'rxjs';
import { AnalysisTemplateService } from '../service/rest/analysistemplate.service';
import { ExpressionEngine } from './expression-engine';
import { JsonFormControls, JsonFormData } from './models';

export interface QuestionnaireState {
  questionnaireId: number;
  formJson: JsonFormData;
}

@Component({
  selector: 'questionnaire',
  templateUrl: 'questionnaire.component.html',
  styleUrls: ['questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RxState],
})
export class QuestionnaireComponent {
  state$ = this.state.select().pipe(filter((curr) => curr.formJson != null));

  exprE: ExpressionEngine = new ExpressionEngine(['value']);

  @Input()
  rootFormGroup: UntypedFormGroup;

  @Input()
  set questionnaireId(questionnaireId: number) {
    this.state.set({ questionnaireId });
  }

  loadQuestionnaire$ = this.state.select('questionnaireId').pipe(
    filter((id) => id != null),
    switchMap((id) => {
      return this.analysisTemplateService.getQuestionnaireForm(id).pipe(
        catchError((error) => {
          console.log(error);
          return EMPTY;
        })
      );
    })
  );

  initForm$ = this.state
    .select('formJson')
    .pipe(tap((formJson) => this.createForm(formJson.controls)));

  constructor(
    private state: RxState<QuestionnaireState>,
    private analysisTemplateService: AnalysisTemplateService,
    private fb: UntypedFormBuilder
  ) {
    this.state.connect('formJson', this.loadQuestionnaire$);
    this.state.hold(this.initForm$);
  }

  createForm(controls: JsonFormControls[]) {
    for (const currentControl of Object.keys(this.rootFormGroup.controls)) {
      this.rootFormGroup.removeControl(currentControl);
    }
    for (const control of controls) {
      const validatorsToAdd = [];

      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }

      this.rootFormGroup.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd)
      );

      if (control.condition != null) {
        this.state.hold(
          this.rootFormGroup.get(control.condition.field).valueChanges.pipe(
            tap((val) => {
              this.exprE.parse(control.condition.expression);
              const ret = this.exprE.evaluate([val]);
              if (ret) {
                this.rootFormGroup.get(control.name).enable();
              } else {
                this.rootFormGroup.get(control.name).disable();
              }
            })
          )
        );
        this.rootFormGroup.get(control.name).disable();
      }
    }
    console.log(this.rootFormGroup);
  }
}
