import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PortalUser } from '../../model/portaluser';
import { BaseService } from '../base.service';
import { TranslationLoadService } from './translation-load.service';
import { Physician } from '../../model/physician';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private portalUserUrl = this.endpointUrl + '/v1/users';
  private portalPublicUserUrl = this.endpointUrl + '/public';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient,
    private translationLoadService: TranslationLoadService
  ) {
    super(config);
  }

  static hasRole(user: PortalUser, roleName: string): boolean {
    if (!user || !user.authorities) {
      return false;
    } else {
      return (
        user.authorities.findIndex((role) => role.authority == roleName) != -1
      );
    }
  }

  static hasRoles(user: PortalUser, roleNames: (string | string[])[]): boolean {
    if (!user?.authorities) {
      return false;
    }
    if (roleNames == null) {
      return true;
    }
    for (const token of roleNames) {
      if (
        (typeof token === 'string' ? [token] : token).reduce(
          (intermediateResult, nextToken) =>
            intermediateResult &&
            user.authorities.map((r) => r.authority).includes(nextToken),
          true
        )
      ) {
        return true;
      }
    }
    return false;
  }

  static isPhysicianDelegate(physician: Physician) {
    return (
      physician.parentPhysicianId != null &&
      !this.hasRole(physician, 'ROLE_CRO_DELEGATE')
    );
  }

  //////////////////////////// PUBLIC
  onetimeaccess(token: string | null): Observable<string> {
    return this.http
      .get<any>(this.portalPublicUserUrl + '/onetimeaccess/' + token)
      .pipe(
        map((resp) => resp.response),
        catchError(this.handleError<string>('onetimeaccess'))
      );
  }

  resetPassword(
    portalUser: PortalUser,
    token: string | undefined
  ): Observable<string> {
    return this.http
      .post<any>(
        this.portalPublicUserUrl + '/resetpassword/' + token,
        portalUser
      )
      .pipe(
        map((resp) => resp.response),
        catchError(this.handleError<string>('resetpassword'))
      );
  }

  checkIfUserExists(username: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.portalPublicUserUrl + '/check/' + username)
      .pipe(catchError(this.handleError<boolean>('checkIfUserExists', false)));
  }

  checkIfDomainExists(username: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.portalPublicUserUrl + '/domain/' + username)
      .pipe(
        catchError(this.handleError<boolean>('checkIfDomainExists', false))
      );
  }

  activateUser(token: string | null): Observable<string> {
    return this.http
      .get<any>(this.portalPublicUserUrl + '/activate/' + token)
      .pipe(
        map((ret) => ret.response),
        catchError(this.handleError<string>('activateUser'))
      );
  }

  userHasPassword(token: string | null): Observable<boolean> {
    return this.http
      .get<boolean>(this.portalPublicUserUrl + '/hasPassword/' + token)
      .pipe(catchError(this.handleError<boolean>('userHasPassword')));
  }

  sendAccessLinkToUser(username: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.portalPublicUserUrl}/sendaccesslink/${username}`)
      .pipe(catchError(this.handleError<boolean>('sendAccessLinkToUser')));
  }

  /////////////////////////// AUTHENTICATED USER

  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<boolean> {
    return this.http.put<boolean>(this.portalUserUrl + '/change-password', {
      oldPassword,
      newPassword,
    });
  }

  resendActivationLink(
    portalUser: PortalUser,
    portalId: number
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.portalUserUrl + `/resendactivation/${portalId}`,
        portalUser
      )
      .pipe(catchError(this.handleError<boolean>('resendactivation')));
  }

  loadPortalUser(): Observable<PortalUser> {
    return this.http.get<PortalUser>(this.portalUserUrl + '/current').pipe(
      map((portalUser: PortalUser) => {
        // this.translationLoadService
        //   .setLanguage(portalUser?.language)
        //   .subscribe();
        return portalUser;
      }),
      catchError(
        this.handleError<PortalUser>('getCurrentPortalUser', new PortalUser())
      ) // Note: maybe return null User in case of error or logout
    );
  }
}
