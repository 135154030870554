import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, timer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../model/appsettings';
import { BaseService } from '../service/base.service';
import { marker as transKey } from '@colsen1991/ngx-translate-extract-marker';
import { AuthLocalstorageService } from './auth-localstorage.service';
import { UnloadService } from './unload.service';

// We use this custom URLEncoder because the HttpParams one from Angular does not encode '+', instead just
// replaces it with 'space'. Therefore, we have problems with passwords including '+' signs.
// AP-658
// https://stackoverflow.com/questions/51942703/character-converting-into-space-in-httpparams-angular-6

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {
  private oauthUrl = this.endpointUrl + '/auth/login';

  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );

  private autoLogoutSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) private config: AppSettings,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService
  ) {
    super(config);
    AuthLocalstorageService.addTimestampChangedListener(this.timestampChanged);
  }

  public initAutoLogout() {
    this.deactivateAutoLogout();
    const sessionEnd = AuthLocalstorageService.getSessionEnd();
    this.autoLogoutSubscription = timer(
      sessionEnd != null ? sessionEnd.getTime() - new Date().getTime() : 0
    ).subscribe({
      error: (error) => console.error('Auto logout error.', error),
      complete: () => {
        this.deactivateAutoLogout();
        this.messageService.add({
          severity: 'warn',
          summary: this.config.BACKOFFICE
            ? 'Your session has expired.'
            : transKey('$global.alert.your-session-has-expired'),
        });
        this.logout(false);
        this.navigateToLogin();
        console.log('Logged out after session timeout.');
      },
    });
  }

  public deactivateAutoLogout() {
    if (this.autoLogoutSubscription) {
      this.autoLogoutSubscription.unsubscribe();
    }
  }

  public login(username: string, password: string): Observable<void> {
    const body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('username', username)
      .set('password', password);
    return this.http
      .post<{ sessionTimeout: number }>(this.oauthUrl, body, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          AuthLocalstorageService.setSessionDuration(response.sessionTimeout);
          this.updateSessionEnd();
          this.initAutoLogout();
          UnloadService.clearActiveTabs();
        })
      );
  }

  public logout(clientOnly: boolean) {
    if (!clientOnly) {
      this.http
        .get(this.endpointUrl + '/auth/logout')
        .pipe(
          tap(() => {
            console.log('Successfully logged out.');
            this.deactivateAutoLogout();
            AuthLocalstorageService.setSessionEnd(null);
          }),
          catchError((error) => {
            console.error('Error logging out.');
            this.deactivateAutoLogout();
            AuthLocalstorageService.setSessionEnd(null);
            return error;
          })
        )
        .subscribe();
    } else {
      console.log('Client successfully logged out.');
      this.deactivateAutoLogout();
      AuthLocalstorageService.setSessionEnd(null);
    }
  }

  public navigateToLogin() {
    this.router.navigate([
      '/login/' +
        (this.translateService.currentLang
          ? this.translateService.currentLang
          : ''),
    ]);
  }

  public isLoggedIn() {
    return AuthLocalstorageService.isLoggedIn() || UnloadService.isLoggedIn();
  }

  public updateSessionEnd() {
    const duration = AuthLocalstorageService.getSessionDuration();
    const sessionEnd = new Date();
    sessionEnd.setSeconds(sessionEnd.getSeconds() + duration);
    AuthLocalstorageService.setSessionEnd(sessionEnd);
  }

  protected timestampChanged = () => {
    if (this?.autoLogoutSubscription?.closed == false) {
      this.initAutoLogout();
    }
  };
}
