import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Date Transformer
 */
@Pipe({ name: 'prepareDate' })
export class PrepareDateForServicePipe implements PipeTransform {
  /**
   * Hier wird von ein mit einer ZeitZone versehenes Date()-Objekt
   * in einen UTC-Timestamp OHNE ZeitZone umgewandelt als <Datum> - 00:00:00
   * <br/>
   * 19.01.1938 11:11:11  CET -> 19.01.1938 00:00 UTC als Timestamp
   * @param date zu beschneidendes Datum
   */
  transform(date: Date): string {
    const transformedDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).toString();

    // console.log( "return from transform: " + transformedDate )
    return transformedDate;
  }

  /**
   * Schneidet Zeit von einem Datum ab und schickt es als
   * UTC timestamp z.b. "1534896000000" zurück
   * @param date
   */
  removeTimeFromDateReturnUTC(date: any): string {
    let newdate;
    // Wenn String dann Date()-Objekt aus String erzeugen.
    if (!(date instanceof Date)) {
      newdate = new Date(date);
    } else {
      newdate = date;
    }
    return new Date(
      Date.UTC(newdate.getFullYear(), newdate.getMonth(), newdate.getDate())
    )
      .getTime()
      .toString();
  }
}
