import { AnalysisCompact } from './analysisCompact';

export class PickupRequest {
  street: string;
  street2: string;
  street3: string;
  city: string;
  zip: string;

  countryCode: string;
  countryTranslation: string;

  contactName: string;
  contactPhone: string;
  contactEmail: string;

  requesterFirstName: string;
  requesterLastName: string;
  requesterUsername: string;

  createDate: string;
  quantity: number;
  pickupDate: string;

  analyses: AnalysisCompact[];
}
