import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  mapToCanActivate,
  mapToCanActivateChild,
} from '@angular/router';
import { AuthGuard, countryGuard } from '@archimed-frontend/archimed-common';
import { ForgotpasswordComponent } from './logged-out/login/forgotpassword.component';
import { LoginComponent } from './logged-out/login/login.component';
import { LogoutComponent } from './logged-out/login/logout.component';
import { ActivationComponent } from './logged-out/register/activation.component';
import { OnetimeaccessComponent } from './logged-out/register/onetimeaccess.component';
import { ResetpasswordComponent } from './logged-out/register/resetpassword.component';
import { NoServiceComponent } from './logged-out/noservice/noservice.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'login/:lang',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'noservice',
    component: NoServiceComponent,
    data: {
      title: 'No Service',
    },
  },
  {
    path: '',
    canActivate: mapToCanActivate([AuthGuard]),
    canActivateChild: mapToCanActivateChild([AuthGuard]),
    loadChildren: () =>
      import('./logged-in/loggedin.module').then((m) => m.LoggedinModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { title: 'logout.log-out' },
  },
  {
    path: 'logout/:lang',
    component: LogoutComponent,
    data: { title: 'logout.log-out' },
  },
  {
    path: 'forgotpassword/:lang',
    component: ForgotpasswordComponent,
    data: { title: 'onetimeaccess.forgot-password' },
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent,
    data: { title: 'onetimeaccess.forgot-password' },
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./shared/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'activate/:token',
    component: ActivationComponent,
    data: { title: 'activation.activation' },
  },
  {
    path: 'activate/:token/:lang',
    component: ActivationComponent,
    data: { title: 'activation.activation' },
  },
  {
    path: 'onetimeaccess/:token',
    component: OnetimeaccessComponent,
    data: { title: 'resetpassword.access-account' },
  },
  {
    path: 'onetimeaccess/:token/:lang',
    component: OnetimeaccessComponent,
    data: { title: 'resetpassword.access-account' },
  },
  {
    path: 'resetpassword/:token',
    component: ResetpasswordComponent,
    data: { title: 'resetpassword.reset-password' },
  },
  {
    path: 'resetpassword/:token/:lang',
    component: ResetpasswordComponent,
    data: { title: 'resetpassword.reset-password' },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
