import { Component } from '@angular/core';
import { PortalService } from '@archimed-frontend/archimed-common';

@Component({
  selector: 'noservice',
  templateUrl: 'noservice.component.html',
})
export class NoServiceComponent {
  currentPortal: any;

  constructor(private portalService: PortalService) {
    this.portalService.portalAnnounced.subscribe(
      (portal) => (this.currentPortal = portal)
    );
  }
}
