<div *ngIf="state$ | async as state" class="questionnaire-component">
  <p>
    <strong translate>questions.bitte-bantworten-sie-die-folgend</strong>
  </p>
  <div class="holder-radio" [formGroup]="rootFormGroup">
    <ng-container *ngFor="let control of state.formJson?.controls">
      <ng-container [ngSwitch]="control.type">
        <div
          *ngSwitchCase="'radio'"
          class="form-group radio"
          [hidden]="rootFormGroup.get(control.name).disabled"
        >
          <p translate>{{ control.label }}</p>
          <ng-container *ngFor="let choice of control.choices; let i = index">
            <input
              type="radio"
              id="{{ control.name }}-{{ i }}"
              [formControlName]="control.name"
              [value]="choice.value"
            />
            <label for="{{ control.name }}-{{ i }}" translate>{{
              choice.label
            }}</label>
          </ng-container>
        </div>
        <div
          *ngSwitchCase="'text'"
          class="form-group"
          [hidden]="rootFormGroup.get(control.name).disabled"
        >
          <p translate>{{ control.label }}</p>
          <input
            type="text"
            id="{{ control.name }}"
            [formControlName]="control.name"
          />
        </div>
        <div
          *ngSwitchCase="'checkbox'"
          class="form-group checkbox"
          [hidden]="rootFormGroup.get(control.name).disabled"
        >
          <input
            type="checkbox"
            id="{{ control.name }}"
            [formControlName]="control.name"
          />
          <label for="{{ control.name }}" translate>{{ control.label }}</label>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
