import { Patient } from './patient';
import { Physician } from './physician';
import { AnalysisReport } from './analysisreport';
import { AnalysisItem } from './analysisitem';
import { Portal } from './portal';
import { QuestionAnwer } from './questionanswer';
import { PortalUser } from './portaluser';
import { AnalysisPool } from './analysispool';

export class Analysis {
  id: string;
  receivedAt: string;
  dbsId: string;
  status: string;
  statusInfo: string;
  notes: string;
  patient: Patient;
  portal: Portal;
  physician: Physician;
  sampleTakenOn: any; // entweder String bei manueller Eingabe oder Date()-Objekt bei DateTimePicker Selektion
  analysisItems: AnalysisItem[] = [];
  analysisReports: AnalysisReport[] = [];
  questionnaireId: number;
  questionAnswers: QuestionAnwer[] = [];
  createdBy: PortalUser;
  createDate: string;
  analysisPool: AnalysisPool;
  dlxProcessState: number;
  selfService: boolean;
  selfServiceAddress: string;
  selfServicePhoneNumber: string;
  patientHasBeenTestedBefore: boolean;
  patientName: string;
}
