import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<never>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap(() => {
        if (this.isPublicOrAuthRequest(request)) {
          return;
        }
        this.authService.updateSessionEnd();
        this.authService.initAutoLogout();
      }),
      catchError((error) => {
        if (this.isPublicOrAuthRequest(request)) {
          return throwError(() => error);
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout(true);
          this.authService.navigateToLogin();
          return of(null);
        }
        return throwError(() => error);
      })
    );
  }

  private isPublicOrAuthRequest(request: HttpRequest<never>) {
    return (
      request.url.match(/\/?api\/public\//) != null ||
      request.url.match(/\/?api\/auth\//) != null
    );
  }
}
