import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Analysis } from '../../model/analysis';
import { AnalysisReport } from '../../model/analysisreport';
import { AnalysisTemplate } from '../../model/analysisTemplate';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PagedResult } from '../../util/pagedresult';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService extends BaseService {
  private reviewUserURL = this.endpointUrl + '/v1/analysis';
  private portalDownload = this.endpointUrl + '/v1/analysis/download/';

  private newReviews = new Subject<number>();
  private newReviewsCurrent = 0;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    super(config);
  }

  numberOfNewAnalysisWaitingToBeDownloaded(): Observable<number> {
    return this.http
      .get<number>(this.reviewUserURL + '/waiting-for-download')
      .pipe(
        // Note: stephan: Counter komplett auf getNewReviews() umstellen
        tap((count: number) => {
          this.newReviews.next(count);
          this.newReviewsCurrent = count;
        })
      );
  }

  getNewReviews(): Observable<any> {
    return this.newReviews.asObservable();
  }

  findByDbsid(
    dbsId: string,
    size?: number,
    page?: number
  ): Observable<PagedResult<Analysis>> {
    let params: HttpParams = new HttpParams();

    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }
    if (dbsId) {
      params = params.set('dbsId', `${dbsId}`);
    }
    // console.log('service, physicianid', physicianId);
    // let url: string = this.reviewUserURL + '/find/';
    // console.log('url', url);
    return this.http
      .get<PagedResult<Analysis>>(
        `${this.reviewUserURL}/find?${params.toString()}`
      )
      .pipe(catchError(this.handleError<PagedResult<Analysis>>('findByDbsId')));
  }

  downloadUrl(analysisReport: AnalysisReport) {
    return this.portalDownload + analysisReport.fileNameHash;
  }

  /**
   * Starts a report download.
   *
   * @param analysisReport Report to download.
   * @param downloaded Must be true if the report has been downloaded before.
   */
  downloadFile(
    analysisReport: AnalysisReport,
    downloaded: boolean
  ): Observable<Blob> {
    return this.http
      .get(this.downloadUrl(analysisReport), { responseType: 'blob' })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/octet-stream',
          });
        }),
        tap((blob: Blob) => {
          // https://stackoverflow.com/questions/39375076/how-to-display-pdf-blob-on-ios-sent-from-my-angularjs-app
          FileSaver.saveAs(blob, analysisReport.originalFileName);
          if (!downloaded) {
            this.newReviewsCurrent = this.newReviewsCurrent - 1;
            this.newReviews.next(this.newReviewsCurrent);
          }
        })
      );
  }

  downloadAll(analysis: Analysis): Observable<Blob> {
    return this.http
      .get(`${this.portalDownload}analysis/${analysis.id}`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        tap(
          (blob: Blob) => {
            FileSaver.saveAs(blob, `${analysis.dbsId}.zip`);
          },
          (error) => {
            // Note: move error handling to calling component.
            this.messageService.add({
              severity: 'error',
              summary: 'An error occurred downloading a file',
              detail: error?.message,
            });
          }
        ),
        catchError(this.handleError<Blob>('downloadAll', null))
      );
  }

  getOrderedTemplates(physicianId: number): Observable<AnalysisTemplate[]> {
    return this.http.get<AnalysisTemplate[]>(
      `${this.reviewUserURL}/templates/${physicianId}`
    );
  }
}
