export class DateTimeUtils {
  /**
   * Converts the local date of a JS Date object into a ISO 8601
   * date string without TZ information ('YYYY-MM-DD').
   *
   * Returns null if the input is null.
   */
  public static dateToISOLocalDate(date: Date) {
    return date != null
      ? `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      : null;
  }
}
