import { AuthLocalstorageService } from './auth-localstorage.service';

/**
 * This class is responsible for keeping track of open tabs and handling the
 * closing of tabs.
 * <br>
 * Open tabs are persisted in a string array in the local storage. If AuthGuard
 * figures we are logged-in the current tab is added to the list. If the
 * beforeunload event is fired the tab is removed.
 *<br>
 * If the beforeunload event figures it is the last tab, login information in
 * the localstorage are removed and are added to the session store. The reason
 * is that the session storage survives a reload and the reload of the last tab
 * should not lead to a log-out, we then check during app initialization if
 * there is login information in the session storage.
 * <br>
 * If the tab is closed, the local storage has no login information because it
 * has been cleared by us and the session storage has none because it has been
 * cleared by the browser. Therefore, the app requires a new login.
 */
export class UnloadService {
  private static readonly tabId = Math.random().toString(36).substring(2, 11);

  private static readonly activeTabsItemName = 'active-tabs';
  private static readonly timestampItemName = 'auth-session-timeout-timestamp';

  static {
    window.addEventListener('beforeunload', () => {
      if (AuthLocalstorageService.isLoggedIn()) {
        UnloadService.prepareUnload();
      }
    });
  }

  /**
   * Check if this is the last open tab. If true persist session end timestamp
   * in session store and remove it from local store.
   * <br>
   * Then remove this tab from active tabs list in local store.
   */
  private static prepareUnload() {
    const activeTabs = UnloadService.getActiveTabs();
    const otherTabs = activeTabs.filter((item) => {
      return item !== UnloadService.tabId;
    });
    if (otherTabs.length == 0) {
      const timestamp = AuthLocalstorageService.getSessionEnd();
      if (timestamp != null) {
        sessionStorage.setItem(
          UnloadService.timestampItemName,
          timestamp.toISOString()
        );
        AuthLocalstorageService.setSessionEnd(null);
      }
    }
    localStorage.setItem(
      UnloadService.activeTabsItemName,
      JSON.stringify(otherTabs)
    );
  }

  public static addActiveTab() {
    const activeTabs = UnloadService.getActiveTabs();
    activeTabs.push(UnloadService.tabId);
    localStorage.setItem(
      UnloadService.activeTabsItemName,
      JSON.stringify(activeTabs)
    );
  }

  public static clearActiveTabs() {
    localStorage.setItem(UnloadService.activeTabsItemName, JSON.stringify([]));
  }

  private static getActiveTabs(): string[] {
    const item = localStorage.getItem(UnloadService.activeTabsItemName);
    return item != null ? JSON.parse(item) : [];
  }

  /**
   * Check if session end is persisted in session store and is valid.
   */
  public static isLoggedIn() {
    const timestamp = sessionStorage.getItem(UnloadService.timestampItemName);
    const sessionEndDate = timestamp != null ? new Date(timestamp) : null;
    AuthLocalstorageService.setSessionEnd(sessionEndDate);
    const now = new Date();
    return sessionEndDate != null && now.getTime() < sessionEndDate.getTime();
  }
}
