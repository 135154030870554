/**
 * This class handles persisting authentication information across tabs.
 * <br>
 * Duration is used to persist the inactivity duration of a session in seconds.
 * This information comes from the login REST call and must be shared across
 * tabs because not each tab must have a login call.
 * <br>
 * The timestamp is used to indicate the time when the session ends when
 * it remains inactive. This entry is necessary because any tab can renew
 * the session and the others are informed about that through this entry.
 */
export class AuthLocalstorageService {
  private static readonly timestampItemName = 'auth-session-timeout-timestamp';
  private static readonly durationItemName = 'auth-session-timeout-duration';

  public static getSessionEnd() {
    const timestamp = localStorage.getItem(
      AuthLocalstorageService.timestampItemName
    );
    return timestamp != null ? new Date(timestamp) : null;
  }

  public static setSessionEnd(timestamp: Date) {
    if (timestamp != null) {
      localStorage.setItem(
        AuthLocalstorageService.timestampItemName,
        timestamp.toISOString()
      );
    } else {
      localStorage.removeItem(AuthLocalstorageService.timestampItemName);
    }
  }

  public static setSessionDuration(duration: number) {
    localStorage.setItem(
      AuthLocalstorageService.durationItemName,
      duration?.toString()
    );
  }

  public static getSessionDuration() {
    const duration = localStorage.getItem(
      AuthLocalstorageService.durationItemName
    );
    return duration != null ? Number.parseFloat(duration) : null;
  }

  public static isLoggedIn() {
    const timestamp = localStorage.getItem(this.timestampItemName);
    const sessionEndDate = timestamp != null ? new Date(timestamp) : null;
    const now = new Date();
    return sessionEndDate != null && now.getTime() < sessionEndDate.getTime();
  }

  public static addTimestampChangedListener(callback: () => void) {
    const key = AuthLocalstorageService.timestampItemName;
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage && event.key === key) {
        callback();
      }
    });
  }
}
