export class TextUtils {
  static stripHtml(text: string) {
    return text != null ? text.replace(/<(?:.|\n)*?>/gm, ' ') : null;
  }

  static stripImgTags(html: string) {
    const imgTagPattern = /<img\s+[^>]*>/gi;
    return html.replace(imgTagPattern, '');
  }

  static limitLength(text: string, length: number): string {
    if (text == null) return null;
    if (text.length > length) {
      return `${text.substr(0, length - 3)}...`;
    } else {
      return text;
    }
  }
}
