import { Inject, Injectable } from '@angular/core';
import {
  AnalysisTemplate,
  APP_CONFIG,
  AppSettings,
  BulkOrderItem,
  PagedResult,
  XlsxBulkOrder,
} from '@archimed-frontend/archimed-common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { BulkOrder } from '../../model/bulkOrder';
import { catchError, map, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { FilterMetadata } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class BulkOrderRestService extends BaseService {
  private url = `${this.endpointUrl}/v1/bulk-order`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  upload(
    file: File,
    physicianId: number,
    portalId?: number
  ): Observable<XlsxBulkOrder> {
    const headers =
      portalId != null
        ? this.getHeadersWithPortalId(portalId)
        : new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('physicianId', physicianId.toString());
    return this.http.post<XlsxBulkOrder>(`${this.url}/upload`, formData, {
      headers,
    });
  }

  createAnalyses(
    physicianId: number,
    bulkOrderItems: BulkOrderItem[],
    xlsxHash: string,
    portalId?: number
  ): Observable<never> {
    const headers =
      portalId != null
        ? this.getHeadersWithPortalId(portalId)
        : new HttpHeaders();
    const body = {
      physicianId,
      bulkOrderItems,
      xlsxHash,
    };
    return this.http.post<never>(`${this.url}/create-analyses`, body, {
      headers,
    });
  }

  list(
    physicianId: number,
    size: number,
    page: number,
    sort: string,
    filterOptions: { [p: string]: FilterMetadata },
    bulkOrderView: boolean
  ): Observable<PagedResult<BulkOrder>> {
    let params = new HttpParams()
      .set('physician-id', physicianId)
      .set('size', size)
      .set('page', page)
      .set('bulk-order-view', bulkOrderView);
    params = sort != null ? params.set('sort', sort) : params;
    return this.http.post<PagedResult<BulkOrder>>(
      `${this.url}/list`,
      filterOptions,
      { params }
    );
  }

  downloadBulkOrderReports(
    bulkOrderId: string,
    firstCreateDateString: string
  ): Observable<string> {
    return this.http
      .get(`${this.url}/${bulkOrderId}/download-reports`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        tap((blob: Blob) => {
          const firstCreateDate: Date = new Date(firstCreateDateString);
          const fileName = `bulk-order_${firstCreateDate.getFullYear()}-${
            firstCreateDate.getMonth() + 1
          }-${firstCreateDate.getDate()}.zip`;
          FileSaver.saveAs(blob, fileName);
        }),
        catchError(this.handleError<Blob>('downloadAll', null)),
        map(() => bulkOrderId)
      );
  }

  downloadTemplate(physicianId: number): Observable<Blob> {
    return this.http
      .get(`${this.url}/download-template/${physicianId}`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError(this.handleError<Blob>('downloadTemplate'))
      );
  }

  getTemplatesForPhysician(physicianId: number) {
    return this.http.get<AnalysisTemplate[]>(
      `${this.url}/physician/${physicianId}/template`
    );
  }

  addTemplateForPhysician(physicianId: number, templateId: number) {
    return this.http.put<void>(
      `${this.url}/physician/${physicianId}/template/${templateId}`,
      null
    );
  }

  removeTemplateForPhysician(physicianId: number, templateId: number) {
    return this.http.delete<void>(
      `${this.url}/physician/${physicianId}/template/${templateId}`
    );
  }

  setBulkOrderEnabled(physicianId: number, enabled: boolean) {
    return this.http.put<void>(
      `${this.url}/physician/${physicianId}/enabled`,
      enabled
    );
  }
}
