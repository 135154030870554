import { Inject, Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CustomerInfo } from '../../model/customerInfo';
import { PagedResult } from '../../util/pagedresult';
import { Invoice } from '../../model/invoice';
import { BaseService } from '../base.service';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Physician } from '../../model/physician';
import { Portal } from '../../model/portal';
import { CustomAnalysisTemplatePrice } from '../../model/customAnalysisTemplatePrice';
import { InvoiceAccounting } from '../../model/invoiceAccounting';
import { QRCodeConfig } from '../../model/qrCodeConfig';
import { RxState } from '@rx-angular/state';
import { RestUtils } from '../../util/restUtils';

@Injectable({ providedIn: 'root' })
export class InvoiceService extends BaseService {
  private invoiceUrl = `${this.endpointUrl}/v1/invoicing`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getCustomPrices(
    physician: Physician,
    portal: Portal
  ): Observable<CustomAnalysisTemplatePrice[]> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.get<CustomAnalysisTemplatePrice[]>(
      `${this.invoiceUrl}/custom-price/${physician.id}`,
      { headers }
    );
  }

  updateCustomPrice(
    physician: Physician,
    pricing: CustomAnalysisTemplatePrice,
    portal: Portal
  ): Observable<void> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.put<void>(
      `${this.invoiceUrl}/custom-price/${physician.id}`,
      pricing,
      { headers }
    );
  }

  addCustomPrice(
    physician: Physician,
    pricing: CustomAnalysisTemplatePrice,
    portal: Portal
  ): Observable<void> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.post<void>(
      `${this.invoiceUrl}/custom-price/${physician.id}`,
      pricing,
      { headers }
    );
  }

  removeCustomPrice(
    physician: Physician,
    pricing: CustomAnalysisTemplatePrice,
    portal: Portal
  ): Observable<void> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.delete<void>(
      `${this.invoiceUrl}/custom-price/${physician.id}/${pricing.analysisTemplate.id}`,
      { headers }
    );
  }

  private customerInfoStateMap = new Map<
    number,
    RxState<{ ci: CustomerInfo }>
  >();

  private getCustomerInfoState(physicianId: number) {
    if (this.customerInfoStateMap.get(physicianId) != null) {
      return this.customerInfoStateMap.get(physicianId);
    } else {
      const state = new RxState<{ ci: CustomerInfo }>();
      state.set({ ci: null });
      this.customerInfoStateMap.set(physicianId, state);
      return state;
    }
  }

  public getCustomerInfoObs(physicianId: number) {
    return this.getCustomerInfoState(physicianId).select('ci');
  }

  public getCustomerInfo(physicianId?: number): Observable<CustomerInfo> {
    let params: HttpParams = new HttpParams();
    if (physicianId != null) {
      params = params.set('physicianId', physicianId);
    }
    return this.http
      .get<CustomerInfo>(`${this.invoiceUrl}/customer-info`, {
        params,
      })
      .pipe(
        tap((ci) =>
          physicianId != null
            ? this.getCustomerInfoState(physicianId).set({ ci })
            : null
        )
      );
  }

  createCustomerInfo(customerInfo: CustomerInfo): Observable<CustomerInfo> {
    return this.http
      .post<CustomerInfo>(`${this.invoiceUrl}/customer-info`, customerInfo)
      .pipe(tap((ci) => this.getCustomerInfoState(ci.portalUser)?.set({ ci })));
  }

  updateCustomerInfo(customerInfo: CustomerInfo): Observable<CustomerInfo> {
    return this.http
      .put<CustomerInfo>(`${this.invoiceUrl}/customer-info`, customerInfo)
      .pipe(tap((ci) => this.getCustomerInfoState(ci.portalUser)?.set({ ci })));
  }

  getInvoices(
    period: string,
    size?: number,
    page?: number,
    portalId?: number,
    portalUserId?: number
  ): Observable<PagedResult<Invoice>> {
    let params: HttpParams = new HttpParams();
    if (period) {
      params = params.set('period', period);
    }
    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }
    if (portalId) {
      params = params.set('portalId', portalId);
    }
    if (portalUserId) {
      params = params.set('portalUserId', portalUserId);
    }
    return this.http
      .get<PagedResult<Invoice>>(`${this.invoiceUrl}/invoices`, { params })
      .pipe(catchError(this.handleError<PagedResult<Invoice>>('getInvoices')));
  }

  getAccountingInvoices(
    portal: Portal,
    year: number,
    month: number
  ): Observable<InvoiceAccounting[]> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.post<InvoiceAccounting[]>(
      `${this.invoiceUrl}/invoices/list`,
      { year, month },
      { headers }
    );
  }

  generateInvoices(
    portal: Portal,
    year: number,
    month: number,
    invoiceDate: string,
    invoiceIds: number[]
  ): Observable<void> {
    const headers: HttpHeaders = this.getHeadersWithPortal(portal);
    return this.http.post<void>(
      `${this.invoiceUrl}/process-invoices`,
      { year, month, invoiceDate, invoiceIds },
      { headers }
    );
  }

  postponeInvoice(invoice: Invoice): Observable<void> {
    return this.http.post<void>(
      `${this.invoiceUrl}/invoice/${invoice.id}/postpone`,
      null
    );
  }

  deleteInvoice(invoice: Invoice): Observable<void> {
    return this.http.delete<void>(`${this.invoiceUrl}/invoice/${invoice.id}`);
  }

  downloadForPeriod(
    portalId: number,
    type: string,
    year: number,
    month: number
  ): Observable<Blob> {
    return this.http
      .post(
        `${this.invoiceUrl}/invoices/${portalId}/${type}`,
        { year, month },
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError((error) => {
          return RestUtils.handleErrorResultInBlobRequest(error);
        }),
        catchError(this.handleError<Blob>('downloadForPeriod'))
      );
  }

  downloadInvoice(invoiceId: number): Observable<Blob> {
    return this.http
      .get(`${this.invoiceUrl}/invoices/${invoiceId}`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError((error) => {
          return RestUtils.handleErrorResultInBlobRequest(error);
        }),
        catchError(this.handleError<Blob>('downloadInvoice'))
      );
  }

  downloadPatientList(invoiceId: number): Observable<Blob> {
    return this.http
      .get(`${this.invoiceUrl}/invoices/${invoiceId}/patient-list`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError((error) => {
          return RestUtils.handleErrorResultInBlobRequest(error);
        })
      );
  }

  getNotInvoiced(physicianId: number, portalId: number): Observable<Invoice[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('physician-id', physicianId);
    params = params.set('portal-id', portalId);
    return this.http.get<Invoice[]>(`${this.invoiceUrl}/not-invoiced`, {
      params,
    });
  }

  removeAnalysis(analysisId: number): Observable<void> {
    return this.http
      .delete<void>(`${this.invoiceUrl}/analysis/${analysisId}`)
      .pipe(catchError(this.handleError<void>('removeAnalysis')));
  }

  updatePrice(analysisId: number, price: number): Observable<void> {
    return this.http
      .put<void>(`${this.invoiceUrl}/analysis/${analysisId}/price`, price)
      .pipe(catchError(this.handleError<void>('updatePrice')));
  }

  updateQrCodeConfig(qrCodeConfig: QRCodeConfig) {
    return this.http.put<void>(
      `${this.invoiceUrl}/qr-code/config`,
      qrCodeConfig
    );
  }

  getQrCodeConfig() {
    return this.http.get<QRCodeConfig>(`${this.invoiceUrl}/qr-code/config`);
  }

  setSelected(invoiceId: number, selected: boolean) {
    return this.http.put<void>(
      `${this.invoiceUrl}/invoice/${invoiceId}/selected`,
      selected
    );
  }

  getOldestNotInvoicedPeriod(portalId: number): Observable<{ period: string }> {
    let params: HttpParams = new HttpParams();
    params = params.set('portal-id', portalId);
    return this.http.get<{ period: string }>(
      `${this.invoiceUrl}/oldest-not-invoiced-period`,
      {
        params,
      }
    );
  }
}
